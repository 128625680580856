@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add to your index.css or custom.css */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blinkCaret {
  from, to { border-right-color: transparent }
  50% { border-right-color: white }
}

.typing-container {
  display: inline-block;
  overflow: hidden;
  border-right: 3px solid;
  white-space: nowrap;
  animation: blinkCaret 0.75s step-end infinite;
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(40, end) 1s 1 normal both;
}
